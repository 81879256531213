/* eslint-disable @typescript-eslint/ban-types */
import { useMutation } from "@apollo/client"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { UpsertPoolMessageMutation, UpsertPoolMessageMutationVariables } from "../../../../../__generated__/UpsertPoolMessageMutation"
import { extractValidationError } from "../../../../components/Form"
import { getQuote, initialValue, replaceMessageBoardStyleClass } from "../../../../utils/slate-plugins-utils"
import { palette, pxToRem } from "../../../../utils/style-utils"
import { UPSERT_POOL_MESSAGE_MUTATION } from "../../../queries"
import MessageEditor from "../../components/MessageBoard/MessageEditor"
import { IMessageFormContext, MessageFormContext } from "../../containers/MessageBoard/Context/MessageFormContext"
import { TNode } from "@udecode/slate-plugins"
import { breakpoints, fontFamily, fontWeight, newPalette, TDeviceType } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { trackActionByKey } from "./message-board-tracking"
import constants from "../../../../../common/constants"

const Notifications = styled.div`
  padding: 0 ${pxToRem(16)};
  display: flex;
`
const Errors = styled.span`
  color: ${palette.red};
  width: 100%;
  padding-bottom: ${pxToRem(12)};
  display: block;
`
const MessageFormWrapper = styled.div`
  background-color: ${newPalette.white};
  display: flex;
  flex-direction: column;
  color: ${newPalette.gray20};
  width: 45rem;

  & > .section-to {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${fontFamily.base};
    font-size: 14px;
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    text-align: left;
    border-bottom: 1px solid ${palette.gray90};
    padding: 0.5rem 0 0.5rem 1rem;
    position: relative;
    background-color: ${palette.white};
    height: 3.25rem;
    box-sizing: border-box;

    & > .private-message {
      display: flex;
      justify-content: space-between;
      flex: 1;
      margin-right: 1rem;
      padding: 0.5rem 0;
      align-items: center;

      .disclaimer {
        color: ${newPalette.gray50};
        margin-right: 0.25rem;
        font-family: ${fontFamily.base};
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: -0.1px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 16px;
        width: 12rem;
        box-sizing: border-box;
        background-color: ${newPalette.gray97};
        margin: 0 1rem;
        height: 100%;
      }
    }
    & > .public-message {
      font-weight: ${fontWeight.medium};
      & > .to-subtitle {
        color: ${newPalette.gray40};
        margin-right: 0.25rem;
        font-weight: ${fontWeight.regular};
      }
    }

    & > .access-container {
      position: relative;
      font-weight: ${fontWeight.medium};
    }
    & > .access-container:before {
      content: "";
      width: 1px;
      background-color: ${palette.gray90};
      height: 2.25rem;
      position: absolute;
      left: -1rem;
      top: -0.5rem;
    }
  }

  & > .section-subject {
    padding: 0rem 1rem;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid ${palette.gray90};

    & > .message-subject {
      width: 100%;
      font-family: ${fontFamily.base};
      font-weight: ${fontWeight.regular};
      font-size: 14px;
      font-style: normal;
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray20};
      height: 1.25rem;
      padding: 0.5rem 0;

      &::placeholder {
        font-family: ${fontFamily.base};
        font-size: 14px;
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
        text-align: left;
        color: ${palette.gray40};
      }

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    width: 100%;
    & > .section-to {
      & > .access-container:before {
        display: none;
      }
    }
  }
`
interface IMessageForm {
  isReply: boolean
  deviceType?: TDeviceType
}
const MessageForm = ({ isReply, deviceType }: IMessageForm) => {
  const messageFormContext: IMessageFormContext = useContext(MessageFormContext)

  const { currentPlayer, upsertPoolMessageMutationVariables, quoteMessage, rootMessage, hideMessageForm, newMessageCallBack } = messageFormContext
  const [upsertPoolMessageMutation, { loading }] = useMutation<UpsertPoolMessageMutation, UpsertPoolMessageMutationVariables>(
    UPSERT_POOL_MESSAGE_MUTATION,
  )
  const [body, setBody] = useState<TNode<{}>[]>(initialValue)
  const [htmlBody, setHtmlBody] = useState("")
  const [subject, setSubject] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [isEmptyBody, setIsEmptyBody] = useState(true)
  const [isBodyTooLong, setIsBodyTooLong] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    if (quoteMessage) {
      const newValue: TNode<{}>[] = getQuote(quoteMessage)
      setBody(newValue)
    } else {
      setBody(initialValue)
    }
  }, [quoteMessage, rootMessage, currentPlayer])

  useEffect(() => {
    // Subject
    if (!isReply && !subject) {
      setIsValid(false)
    }
    // Body
    else if (isEmptyBody || isBodyTooLong) {
      setIsValid(false)
      if (isBodyTooLong) {
        setError(`Body has too many characters`)
      }
    } else {
      setError(``)
      setIsValid(true)
    }
  }, [isReply, subject, isEmptyBody, isBodyTooLong])

  const validateBeforeSubmit = () => {
    if (isValid) {
      const variables: UpsertPoolMessageMutationVariables = {
        ...upsertPoolMessageMutationVariables,
        body: JSON.stringify(body),
        subject: isReply ? undefined : subject,
        htmlBody: replaceMessageBoardStyleClass(htmlBody),
      }
      trackActionByKey(quoteMessage ? "send-quote" : isReply ? "send-reply-to-topic" : "send-new-topic")
      upsertPoolMessageMutation({ variables })
        .then((_res) => {
          Promise.all([messageFormContext.refreshQuery()]).then(() => {
            hideMessageForm()
            if (typeof newMessageCallBack === "function") {
              newMessageCallBack(isReply)
            }
          })
        })
        .catch((err) => {
          const apiErrors = extractValidationError(err)
          setError(apiErrors.message)
        })
    }
  }

  const onBodyChange = (newValue, htmlBody: string, isEmpty = true, textSize = 0) => {
    setBody(newValue)
    setHtmlBody(htmlBody)
    setIsEmptyBody(isEmpty)
    setIsBodyTooLong(textSize > constants.MAX_CHARACTERS_PER_MESSAGE_BOARD_MESSAGE)
  }
  const onCancelClick = () => {
    hideMessageForm()
    trackActionByKey(quoteMessage ? "discard-quote" : isReply ? "discard-reply-to-topic" : "discard-new-topic")
  }
  return (
    <MessageFormWrapper>
      {!isReply && (
        <div className="section-subject">
          <input
            type="text"
            className="message-subject"
            id="subject_input"
            autoComplete="off"
            placeholder="Subject"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSubject(event.target.value)}
          />
        </div>
      )}
      <MessageEditor
        defaultValue={body}
        onBodyChange={onBodyChange}
        id="new-message"
        loading={loading}
        isValid={isValid}
        onCancel={onCancelClick}
        onConfirm={validateBeforeSubmit}
        deviceType={deviceType}
      />
      <Notifications>{error && <Errors>{error}</Errors>}</Notifications>
    </MessageFormWrapper>
  )
}

export default MessageForm
